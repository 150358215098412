import axios from './axios.instance';

export default {
  auth: {
    signIn: (data) => axios.post('/auth/admin/login/email', data),
    getProfile: () => axios.get('/auth/me'),
  },
  users: {
    getUsers: (params) => axios.get('/users', { params }),
    getUser: (id) => axios.get(`/users/${id}`),
    updateUser: (id, data) => axios.patch(`/users/${id}`, data),
    deleteUser: (id) => axios.delete(`/users/${id}`),
    createUser: (data) => axios.post('/users', data),
  },
  userDeals: {
    getList: (params) => axios.get('/user-deals', { params }),
  },
  gifts: {
    getGifts: (params) => axios.get('/gifts', { params }),
    getGift: (id) => axios.get(`/gifts/${id}`),
    updateGift: (id, data) => axios.patch(`/gifts/${id}`, data),
    createGift: (data) => axios.post('/gifts', data),
    getTopGifts: (params) => axios.get('gifts/statistic/repeated', { params }),
  },
  categories: {
    getCategories: (params) => axios.get('/categories', { params }),
    updateCategory: (id, data) => axios.patch(`/categories/${id}`, data),
    createCategory: (data) => axios.post('/categories', data),
  },
  files: {
    uploadFile: (data) => axios.post('/files/upload', data),
  },
  parserRules: {
    getParserRules: (params) => axios.get('/parser-rules', { params }),
    getParserRule: (id) => axios.get(`/parser-rules/${id}`),
    updateParserRule: (id, data) => axios.patch(`/parser-rules/${id}`, data),
    deleteParserRule: (id) => axios.delete(`/parser-rules/${id}`),
    createParserRule: (data) => axios.post('/parser-rules', data),
    testParserRule: (params) => axios.get('/parser-rules/test', { params }),
  },
  parserHosts: {
    createParserHost: (data) => axios.post('/parser-hosts', data),
    deleteParserHost: (id) => axios.delete(`/parser-hosts/${id}`),
    getParserHosts: (params) => axios.get('/parser-hosts', { params }),
  },
  parserFails: {
    getParserFails: (params) => axios.get('/parser-fails', { params }),
    updateParserFail: (id, data) => axios.patch(`/parser-fails/${id}`, data),
  },
  followers: {
    getFollowers: (params) => axios.get('/followers', { params }),
  },
  deals: {
    getDeals: (params) => axios.get('/deals', { params }),
    getDeal: (id) => axios.get(`/deals/${id}`),
    updateDeal: (id, data) => axios.patch(`/deals/${id}`, data),
    createDeal: (data) => axios.post('/deals', data),
    deleteDeal: (id) => axios.delete(`/deals/${id}`),
  },
  countries: {
    getCountries: () => axios.get('/countries'),
  },
  stores: {
    getStores: (params) => axios.get('/stores', { params }),
  },
  reports: {
    getReports: (params) => axios.get('/reports', { params }),
  },
  dashboard: {
    getDashboard: () => axios.get('/dashboard'),
  },
  currency: {
    getCurrency: () => axios.get('/currencies'),
  },
  influencers: {
    handlePay: (id) => axios.patch(`/affiliate-sales/users/${id}/mark-as-paid`),
    getInfluencersStatistic: () => axios.get('/affiliate-sales/statistic'),
  },
};
